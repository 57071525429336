import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import App from './App/index';
import MobileApp from './App/mobileIndex';
import * as serviceWorker from './serviceWorker';
import reducer from './store/reducer';
import config from './config';

import './assets/scss/style.scss';

const store = createStore(reducer);
const isMobile = window.matchMedia("only screen and (max-width: 1024px)").matches;
let app="";
if(isMobile){
    app = (<MobileApp />);
    
} else{
    app = (
        <Provider onContextMenu={(e)=> e.preventDefault()} store={store}>
            <BrowserRouter basename={config.basename}>
                <App />
            </BrowserRouter>
        </Provider>
    );
}


ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
