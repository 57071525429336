import React, { Component, Suspense } from 'react';
//import { KeycloakProvider } from '@react-keycloak/web';
import { Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import Keycloak from 'keycloak-js'

import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import routes from "../route";
import config from '../config';
const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

class App extends Component {
    constructor(props) {
        super(props);
        this.state = { keycloak: null, authenticated: false };
      }
      componentDidMount() {
        const keycloak = Keycloak({
          "url": config.KEYCLOAKURL,
          "realm": config.KEYCLOAKRELAM,
          "clientId": config.KEYCLOAKCLIENTID
      });
      keycloak.init({onLoad: 'login-required', checkLoginIframe: false}).then(authenticated => {
            this.setState({ keycloak: keycloak, authenticated: authenticated })
            if (authenticated){
                window.keycloak = keycloak;
                window.accessToken = keycloak.token;
            }
        })
      }
    render() {
        const menu = routes.map((route, index) => {
          return (route.component) ? (
              <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                      <route.component {...props} />
                  )} />
          ) : (null);
        });
        console.log(this.state.keycloak);
        if (this.state.keycloak) {
            if (this.state.authenticated) return (
                <Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader/>}>
                        <Switch>
                            {menu}
                            <Route path="/" component={AdminLayout} />
                        </Switch>
                    </Suspense>
                </ScrollToTop>
                </Aux>
            ); else return (<div>Unable to authenticate!</div>)
          }
          return (
            <div>Initializing Authentication...</div>
          );
    }
}

export default App;