export default {
    defaultPath: '/dashboard/default',
    basename: '/react/default', // only at build time to set, like ///able-pro/react/default
    layout: 'vertical', // vertical, horizontal
    subLayout: '', // horizontal-2
    collapseMenu: false, // mini-menu
    layoutType: 'menu-light', // menu-dark, menu-light, dark
    headerBackColor: 'header-blue', //background-blue,background-red, background-purple, background-info,background-green,background-dark, background-grd-blue, background-grd-red, background-grd-purple, background-grd-info, background-grd-green, background-grd-dark, background-img-1,background-img-2, background-img-3, background-img-4, background-img-5, background-img-6
    rtlLayout: false,
    navFixedLayout: true,
    headerFixedLayout: false,
    boxLayout: false,
    ADMINHEADER:{
        headers: { 'Authorization': 'zaYrNtZY6bc3ImWoh0ZIz5Zs2jQ', 'Content-Type':'application/json', 'Cache-Control':'no-cache' }
      } ,
    ADMINPOIHEREHEADER:{
        headers: {
                'Authorization':'Basic aW1hcHM6YWRtaW5AbWFwcw==',
                'Content-Type': 'application/json',
                'Cache-Control':'no-cache'
        }
    },
    KEYCLOAKURL:'https://keycloak.intents.mobi/auth',
    KEYCLOAKRELAM:'DevIntentsV2',
    //KEYCLOAKRELAM:'devintents',
    KEYCLOAKCLIENTID:'react_frontend',
    ADMINPOILISTALL:'https://dev-driver.intents.mobi/api/admin_redemption_poi_list.php?method=POILISTADMINALL',
    ADMINPOIIDDETAILS:'https://dev-driver.intents.mobi/api/admin_poi_approve.php?method=POIIDDETAILS',
    ADMINNEARPOILIST:'https://dev-driver.intents.mobi/api/admin_poi_approve.php?method=NEARPOILIST',
    ADMINPOSTPOIDATA:'https://dev-driver.intents.mobi/api/poi_to_mongo.php?method=POSTPOIDATA',
    ADMINREDEMPTIONPOILIST:'https://dev-driver.intents.mobi/api/admin_redemption_poi_list.php?method=REDEMPTIONPOILIST',
    ADMINREDEMPTIONIMPORT:'https://dev-driver.intents.mobi/api/admin_redemption_poi_list.php?method=REDEMPTIONPOIIMPORT',
    ADMINREDEMPTIONEXPORT:'https://dev-driver.intents.mobi/api/admin_redemption_poi_list.php?method=REDEMPTIONPOIEXPORT',
    ADMINREDEEMREQST:'https://dev-driver.intents.mobi/api/reedem_request.php?method=REEDMREQ',
    ADMINAPPROVLIST:'https://dev-driver.intents.mobi/api/admin_aoi.php?method=APPROVEDPOILIST',
    CRETUPTAOI:'https://dev-driver.intents.mobi/api/admin_aoi.php?method=CREATEUPDATEAOI',
    LISTAOI:'https://dev-driver.intents.mobi/api/admin_aoi.php?method=GETAOILIST',
    AOIDETAILS:'https://dev-driver.intents.mobi/api/admin_aoi.php?method=AOIDETAILS',
    LISTCLIENT:'https://dev-driver.intents.mobi/api/admin_aoi.php?method=GETCLIENTLIST',
    LISTPOI:'https://dev-driver.intents.mobi/api/admin_aoi.php?method=GETPOILIST',
    ADDPOISUBCATEGORY:'https://dev-driver.intents.mobi/api/admin_aoi.php?method=ADDPOISUBCATEGORY',
    GETPOICATEGORYLIST:'https://dev-driver.intents.mobi/api/admin_aoi.php?method=GETPOICATEGORYLIST',
    GETPOISUBCATEGORYLIST:'https://dev-driver.intents.mobi/api/admin_aoi.php?method=GETPOISUBCATEGORYLIST',
    APPROVEPOIIDDETAILS:'https://dev-driver.intents.mobi/api/admin_aoi.php?method=POIIDDETAILS',
    PENDINGPOILISTGRID:'https://dev-driver.intents.mobi/api/admin_redemption_poi_list.php?method=POIGRIDLIST',
    DISAPPROVEPOIGRID: 'https://dev-driver.intents.mobi/api/admin_redemption_poi_list.php?method=DISAPPROVEPOIGRID',
    GETPOIHERECATEGORYLIST:'https://maps.intents.mobi/client/here/api/data',
    ADDPOIHEREDATA:'https://dev-driver.intents.mobi/api/admin_aoi.php?method=STOREPOIHEREDATA',
    ADMINLOGIN:'https://dev-driver.intents.mobi/api/admin_login.php?method=ADMINLOGIN',
    GETPOITOMTOMCATEGORYLIST:'https://dev-driver.intents.mobi/api/admin_aoi.php?method=GETPOITOMTOMCATEGORYLIST',
    ADDPOITOMTOMDATA:'https://dev-driver.intents.mobi/api/admin_aoi.php?method=STOREPOITOMTOMDATA',
    GETPOITOMFORMDETAILS:'https://dev-driver.intents.mobi/api/admin_aoi.php?method=TOMIDDETAILS',
    OPENSTREETMAPURL:'https://osm.intents.mobi/map/',
    GETBLOCKEDLISTALL:'https://dev-driver.intents.mobi/api/profile_details.php?method=GETBLOCKEDLISTALL', 
    GETUSERLISTALL:'https://dev-driver.intents.mobi/api/profile_details.php?method=GETUSERLISTALL', 
    GETBLOCKUSERDET:'https://dev-driver.intents.mobi/api/profile_details.php?method=GETBLOCKUSERDET',
    ADDDISABLEDUSER:'https://dev-driver.intents.mobi/api/profile_details.php?method=ADDDISABLEDUSER',
    GETEXPIRYTYPELIST:'https://dev-driver.intents.mobi/api/profile_details.php?method=GETEXPIRYTYPELIST',
    GETPOITOMTOMCITY:'http://dev-driver.intents.mobi/intents/plugged_backend/api/admin_aoi.php?method=GETPOITOMTOMCITY',
    GETPOCCLIENTBPOILIST:'https://dev-driver.intents.mobi/api/tomtom_poc_data.php?method=GETPOCCLIENTBPOILIST',
    POCCLIENTBPOIIDDETAILS:'https://dev-driver.intents.mobi/api/tomtom_poc_data.php?method=GETPOCCLIENTBPOIIDDETAILS',
    STOREPOCPOITOMTOMDATA:'https://dev-driver.intents.mobi/api/tomtom_poc_data.php?method=STOREPOCPOITOMTOMDATA',
    GETPOCTOMTOMFORMIDDETAILS:'https://dev-driver.intents.mobi/api/tomtom_poc_data.php?method=GETPOCTOMTOMFORMIDDETAILS',
    POIAPPRPAYDETAILS:'https://dev-driver.intents.mobi/api/adminpanel_dashboard.php?method=ADMINPANELDASHBOARD',
    SUBMITACTIONANSWER: 'https://dev-admin.intents.mobi/v2/user-action/admin/submit',
    FETCHACTIONS: 'https://dev-admin.intents.mobi/v2/user-action/admin',
    IMAGEBASEURL: 'https://dev-poi.s3.ap-south-1.amazonaws.com/',
    FETCHACTIONTYPES: 'https://dev-admin.intents.mobi/v2/user-action/admin/meta',
    FETCHSTATS: 'https://dev-admin.intents.mobi/v2/user/admin/stats',
    FETCHACTIONDETAILS: 'https://dev-admin.intents.mobi/v2/user-action/admin/getData',
    DISCARDPOI: 'https://dev-admin.intents.mobi/v2/user-action/admin/discard',
    FETCHREVIEWACTIONS: 'https://dev-admin.intents.mobi/v2/user-action/admin/recentUserActions?userId=',
    SUBMITREVIEW: 'https://dev-admin.intents.mobi/v2/user-action/admin/submitReviewAnswer',
    BLOCKUSER: 'https://dev-admin.intents.mobi/v2/user/admin/user/block',
    CATEGORIESLISTALL: 'https://dev-admin.intents.mobi/v2/user-categories/',
    CATEGORIESLISTINDIVIDUAL: 'https://dev-admin.intents.mobi/v2/user-categories/get',
    CATEGORIESUPDATE: 'https://dev-admin.intents.mobi/v2/user-categories/',
    //TBRUSERS: 'https://dev-admin.intents.mobi/v2/user/tbrUsers',
    TBRUSERTOPDENSITY:'https://dev-admin.intents.mobi/v2/user/tbrUserTopDensity?userId=',
    //FETCHTBRUSERACTIONS:'https://dev-admin.intents.mobi/v2/user-action/admin/tbrRecentUserActions?userId=',
    SUBMITUSERREVIEW: 'https://dev-admin.intents.mobi/v2/user-action/admin/submitUserReviewAnswer',
    TBRUSERACTIONSDENSITYBASED:'https://dev-admin.intents.mobi/v2/user-action/admin/tbrDensityBasedUserActions?userId=',
    SUBMITTASKACTIONANSWER: 'https://dev-admin.intents.mobi/v2/task/admin/submit',
    FETCHTASKPREPROCESS:'https://dev-admin.intents.mobi/v2/task/admin/preprocess/fetch',
    SUBMITTASKPREPROCESS:'https://dev-admin.intents.mobi/v2/task/admin/preprocess/submit',
    POIFETCHDATA:"https://dev-admin.intents.mobi/v2/poi/admin/getPoiData",
    POIREVIEWEXCEL:"https://dev-admin.intents.mobi/v2/poi/admin/uploadPoiData",
    POIREVIEWFILELIST:"https://dev-admin.intents.mobi/v2/poi/admin/poiReviewData/list",
    POIS3FILELIST:"https://dev-admin.intents.mobi/v2/poi/admin/poiReviewData/details",
    CATEGORIESLIST: 'https://dev-admin.intents.mobi/v2/categories/get',
    CITYLIST: 'https://dev-admin.intents.mobi/v2/poi/city/list',
    TASKSTATSCOUNT: 'https://dev-admin.intents.mobi/v2/user-action/admin/counts', 
    REVIEWUSERS:'https://dev-admin.intents.mobi/v2/user/reviewUsers',
    FETCHUSERREVIEWUSERACTIONS:'https://dev-admin.intents.mobi/v2/user-action/admin/UserReviewUserActions',
    FETCHTASKMAPPROCESS:'https://dev-admin.intents.mobi/v2/task/admin/taskmap/fetch',
    FETCHNEARBYPOI: 'https://dev-admin.intents.mobi/v2/user-action/nearByPois',
    PAYMENTPOIFETCHDATA:'https://dev-admin.intents.mobi/v2/user-redeem/admin/upiRequests',
    POSTPAYMENTUPLOAD: 'https://dev-admin.intents.mobi/v2/user-redeem/admin/uploadPostPaymentData',
    PAYMENTDRIVINGFETCHDATA:"https://dev-driver.intents.mobi/api/driving_lucky_winners.php?method=DRIVERSLIST",
    POSTPAYMENTDRIVINGUPLOAD: "https://dev-driver.intents.mobi/api/driving_lucky_winners.php?method=DRIVINGPOSTPAYMENT",
};
